import { IncidentStatus } from "../../.rest-hooks/types/incidents.yml";

export const ACTIVE_STATUSES = [
  IncidentStatus.BEINGCONTROLLED,
  IncidentStatus.CONTAINED,
  IncidentStatus.GOING,
  IncidentStatus.PATROL,
] satisfies readonly IncidentStatus[];

export const UNCONTAINED_STATUSES = [
  IncidentStatus.BEINGCONTROLLED,
  IncidentStatus.GOING,
] satisfies readonly IncidentStatus[];

export const OUT_STATUSES: readonly IncidentStatus[] = [
  IncidentStatus.OUT,
  IncidentStatus.OUTAMALGAMATED,
] satisfies readonly IncidentStatus[];
